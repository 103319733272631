import React from 'react';
import styled from 'styled-components';

const IconSvg = styled.svg`
  width: 11px;
  height: 10px;
`;

const HeartIcon = () => (
  <IconSvg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="#D9E14A"
      d="M14 0v2h-2v2h-2V2H8V0H4v2H2v2H0v6h2v2h2v2h2v2h2v2h2v2h2v-2h2v-2h2v-2h2v-2h2v-2h2V4h-2V2h-2V0z"
      fillRule="evenodd"
    />
  </IconSvg>
);

export default HeartIcon;
