import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from '../Images/Logo';
import Logo111 from '../Images/Logo111';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 16px;
  gap: 32px;

  @media only screen and (min-width: 768px) {
    flex-direction: column;
    gap: 36px;
  }
`;

const IconHolder = styled.div`
  witdh: 100%;
  cursor: pointer;
`;

const Menu = styled.ul`
  position: absolute;
  witdh: 100%;
  color: var(--primary);
  border: none;
  font-size: 9px;
  line-height: 0;
  letter-spacing: 0.5px;

  animation: openMenu 0.5s forwards;
  animation-iteration-count: 1;

  @keyframes openMenu {
    0% {
      padding: 0;
      opacity: 0;
      top: 52px;
      border: none;
    }
    100% {
      padding: 12px;
      opacity: 1;
      top: 70px;
      border-top: 1px solid var(--primary);
      border-bottom: 1px solid var(--primary);
    }
  }

  @media only screen and (min-width: 768px) {
    font-size: 11px;
    line-height: 2px;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Heading = styled.h1`
  font-size: 36px;
  color: var(--primary);
  line-height: 150%;
  padding: 0;
  width: 100%;
  text-align: center;
  margin: 0;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }
`;

const Header = () => {
  const [navOpen, setNavOpen] = useState<boolean>(false);

  return (
    <HeaderWrapper>
      {navOpen ? (
        <>
          <IconHolder onClick={() => setNavOpen(false)}>
            <Logo />
          </IconHolder>
          <Menu>Blessed are the curious for they shall have adventures.</Menu>
          <HeadingWrapper>
            <Heading>
              IS PRIME $<Logo111 /> YET?
            </Heading>
          </HeadingWrapper>
        </>
      ) : (
        <>
          <IconHolder onClick={() => setNavOpen(true)}>
            <Logo />
          </IconHolder>
          <HeadingWrapper>
            <Heading>
              IS PRIME $<Logo111 /> YET?
            </Heading>
          </HeadingWrapper>
        </>
      )}
    </HeaderWrapper>
  );
};

export default Header;
