import * as React from 'react';
import styled from 'styled-components';
import HeartIcon from '../Images/HeartIcon';

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  padding: 0 16px 16px;
  color: white;
  flex-wrap: wrap;
  font-size: 12px;
  opacity: 0.2;
  transition: opacity 0.5s;
  gap: 0;

  &:hover {
    opacity: 1;
  }

  @media only screen and (min-width: 768px) {
    justify-content: space-between;
    padding: 0 16px;
  }
`;

const MadeWith = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const TwitterLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: var(--primary);
  }
`;

const ParallelLink = styled.a`
  color: white;
  text-decoration: none;

  &:hover {
    color: var(--primary);
  }
`;

const Affiliation = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  flex-wrap: wrap;
  font-style: italic;
  letter-spacing: 0.5px;

  @media only screen and (min-width: 768px) {
    flex-wrap: nowrap;
  }
`;

const Footer = () => (
  <StyledFooter>
    <MadeWith>
      Made with <HeartIcon /> by{' '}
      <TwitterLink href="https://twitter.com/cryptorinusETH" target="_blank">
        cryptorinus.eth
      </TwitterLink>
      & community
    </MadeWith>
    <Affiliation>
      Not affiliated with{' '}
      <ParallelLink href="https://parallel.life/" target="_blank">
        Parallel
      </ParallelLink>
    </Affiliation>
  </StyledFooter>
);

export default Footer;
