import React, { FunctionComponent } from 'react';
import { PageProps } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';
import { SEO } from '../SEO/SEO';
import Header from './Header';
import Footer from './Footer';
// import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useWindowSize } from '../../hooks/useWindowSize';

import 'normalize.css';

export const GlobalStyle = createGlobalStyle`
	:root {
		font-size: 62.5%;
		--primary: #DEF141;
		--augencore: #F06C15;
		--shroud: #6C15F0;
		--kathari: #058DCC;
		--marcolian: #E30A1D;
		--earthen: #51DE24;
		--common: linear-gradient(-45deg, #1d14eb, #1948e0, #1d14eb, #1948e0);
		--uncommon: linear-gradient(-45deg, #e317c8, #5c1fde, #e317c8, #5c1fde);
		--rare: linear-gradient(-45deg, #e9931c, #e3b61f, #e9931c, #e3b61f);
		--legendary: linear-gradient(-45deg, #e22020, #ea6c18, #e22020, #ea6c18);
		--prime: linear-gradient(-45deg, #28d9e1, #16eb8f, #28d9e1, #16eb8f);
		--white: #fff;
	}

	*,
		*::before,
		*::after {
		box-sizing: border-box;
	}

	html {
		scroll-behavior: smooth;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: transparent;
		background: black;
		padding: 16px;
	}

	body {
		// font-size: 1.6rem;
		font-family: 'Chakra Petch', sans-serif;
		border: 1px solid rgb(222, 241, 65, 0.3);
	}

	a {
		color: currentColor;
		display: block;
		text-decoration: none;
	}

	button {
		background: transparent;
		border: none;
		cursor: pointer;
		padding: 0;
		outline: none;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin: 0;
		font-size: 100%;
		font-weight: normal;
	}

	img {
		display: block;
		height: auto;
		width: 100%;
	}

	input,
	select {
		border: none;
		text-rendering: optimizeLegibility;
		-webkit-font-smoothing: antialiased;
	}

	ol,
	ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	p {
		margin: 0;
	}

	svg {
		fill: currentColor;
		height: 100%;
		width: 100%;
	}
`;

const Container = styled.section<{ height: number; width: number }>`
  ${({ height }) =>
    height
      ? `
			height: calc(${height}px - 32px);
		`
      : `
		`}

  display: grid;
  grid-template-rows: 20% 75% 5%;

  @media only screen and (min-width: 768px) {
    grid-template-rows: auto 1fr 5%;
    height: calc(100vh - 32px);
  }
`;

const Main = styled.section`
  padding: 16px;
`;

export const Layout: FunctionComponent<Omit<PageProps, 'children'>> = ({ children, location }) => {
  const { height, width } = useWindowSize();

  return (
    <Container height={height} width={width}>
      <GlobalStyle />
      <SEO location={location} />
      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
};

export default Layout;
