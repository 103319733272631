/* eslint-disable import/no-unresolved */

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

export const onInitialClientRender = () => {
    document.getElementById("___gatsby").style.display = "block"
    setTimeout(function() {
      document.getElementById("___loader").style.display = "none"
    }, 400)
  }

export { wrapRootElement } from './src/components/wrapRootElement';
export { wrapPageElement } from './src/components/wrapPageElement';
